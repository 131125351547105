import React from 'react';
import Layout from "../../components/Layout/ru";
import {Button, Container} from "@mui/material";
import * as styles from "./style.module.css";
import {StaticImage} from "gatsby-plugin-image";
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';



function AuthorPage({location}) {

  return (
    <Layout
      location={location}
      title='Филипп Неделев'
      description='Информация и контактные данные Неделева Филиппа'
    >
      <div className={styles.page}>
        <Container maxWidth="md" style={{height: "100%"}}>
          <div className={styles.contentWrapper}>
            <div className={styles.avatarWrapper}>
              <StaticImage
                className={styles.avatar}
                src="../../../static/img/avatar.png"
                placeholder="blurred"
                alt="мое фото"
              />
            </div>
            <div className={styles.textWrapper}>
              <h1 style={{marginTop:0}}>Филипп Неделев</h1>
              <h2>Full Stack Developer и Предприниматель</h2>
              <p className={styles.paragraph}>
                Я работаю веб разработчиком уже {new Date().getFullYear() - 2017} лет.<br/>
                Моя основная работа это фронтент. Уже несколько лет работаю над платформами
                связанными, так или иначе, с недвижимостью.<br/>
                В свободное от основной работы время помогаю молодым разработчикам в обучении и поиске работы,
                а также небольшим стартапам в поиске решений для их бизнеса.
              </p>
              <p className={styles.paragraph}>
                Если у тебя есть вопросы, ты нашел | нашла ошибку, или у тебя есть предложения как сделать сайт лучше,
                ты можешь связаться со мной
              </p>
              <div style={{width:"290px",display:"flex",flexDirection:"column"}}>
                <a href="https://t.me/NedelevPhilip" target="_blank" style={{width:"100%",margin:"10px 0"}}>
                  <Button variant="contained"
                          style={{backgroundColor:"#0088cc",justifyContent:"start"}}
                          startIcon={<TelegramIcon />}
                          fullWidth
                  >
                    Напиши мне в Telegram
                  </Button>
                </a>
                <a href="https://www.linkedin.com/in/philip-nedelev-9a7264b5/" target="_blank" style={{width:"100%",margin:"10px 0"}}>
                  <Button variant="contained"
                          style={{backgroundColor:"#0077b5",justifyContent:"start"}}
                          startIcon={<LinkedInIcon />}
                          fullWidth
                  >
                    Установи контакт в Linkedin
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
}



export default AuthorPage;